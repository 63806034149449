import { Typography } from '@mui/material';
import React from 'react';

import Button from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';

import content from './content';

export default function IntroSlide(props: {
    nextURL: string;
    onClose: () => Promise<void>;
}) {
    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'job'}
            onClose={props.onClose}
        >
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant={'h1'}>
                        {content.intro.title}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant='body2'
                        style={{
                            marginTop: '0.875rem',
                            marginBottom: '2.5rem'
                        }}
                    >
                        {content.intro.body}
                    </Typography>
                    <div style={{ marginRight: 'auto' }}>
                        <Button to={props.nextURL} variant='contained'>
                            {content.intro.cta}
                        </Button>
                    </div>
                </div>
            </div>
        </ColumnCard>
    );
}
