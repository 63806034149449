import moment from 'moment';
import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useParams,
    useRouteMatch
} from 'react-router-dom';

import { convertCreateInterviewDetailsToCreateInterviewDetailsRequest } from '../../../services/convert/interview';
import { createEmptyJob } from '../../../services/convert/job';
import { createInterview } from '../../../services/interviews';
import { createJob, getJob } from '../../../services/jobs';
import { getEligibleUserCount } from '../../../services/user';
import { CreateInterviewDetails } from '../../../types/interview';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { FirebaseContext } from '../../context/FirebaseContext';
import { UserContext } from '../../context/UserContext';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';
import {
    useCreateJobSidebarButtons,
    useDefaultSidebarButtons
} from '../../global/layouts/MainContainer/SidebarButton/SidebarList';
import { DateYMDString } from '../BookInterview/types';

import CalendarSlide from './CalendarSlide';
import CompleteSlide from './CompleteSlide';
import DescriptionSlide from './DescriptionSlide';
import EducationSlide from './EducationSlide';
import EnvironmentSlide from './EnvironmentSlide';
import IntroSlide from './IntroSlide';
import SalarySlide from './SalarySlide';
import TimesSlide from './TimesSlide';
import TitleSlide from './TitleSlide';

const routes = {
    intro: { next: 'title', prev: 'complete' },
    title: { next: 'education', prev: 'intro' },
    education: { next: 'environment', prev: 'title' },
    environment: { next: 'salary', prev: 'education' },
    salary: { next: 'description', prev: 'environment' },
    description: { next: 'interviewcalendar', prev: 'salary' },
    interviewcalendar: { next: 'interviewtime', prev: 'description' },
    interviewtime: { next: 'complete', prev: 'interviewcalendar' },
    complete: { next: 'intro', prev: 'interviewtime' }
};

export default function JobPost() {
    const [redirect, setRedirect] = useState<string>('');
    const location = useLocation();
    const { path } = useRouteMatch();
    const { companyID, jobID } =
        useParams<{ uid: string; companyID: string; jobID: string }>();
    const [query, setQuery] = useState<CandidateSearch>({});

    const [candidateCount, setCandidateCount] = useState(0);

    const {
        user,
        company: currentCompany,
        setCompanyID
    } = useContext(UserContext);
    const { auth } = useContext(FirebaseContext);

    const [job, setJob] = useState<SimpleJob>(createEmptyJob());

    const [interview, setInterview] = useState<CreateInterviewDetails>({
        id: 0,
        jobID: 0,
        interviewerID: user.id,
        split: true,
        splitTime: 8,
        details: []
    });

    const defaultSideBarButtons = useDefaultSidebarButtons(user.userID);
    const scrollButtons = useCreateJobSidebarButtons(
        user.userID,
        currentCompany.id,
        job.id
    );

    useEffect(() => {
        if (companyID) {
            setCompanyID(parseInt(companyID, 10));
        }
    }, [companyID, setCompanyID]);

    const flowType = useMemo(
        () => (location.pathname.includes('/edit/') ? 'edit' : 'create'),
        [location.pathname]
    );

    const getPageURL = (
        page: keyof typeof routes,
        reverse: boolean = false
    ) => {
        let baseURL = `/${flowType}/${user.userID}/job/${currentCompany.id}`;
        if (flowType === 'edit') {
            baseURL += `/${job.id}`;
        }
        if (reverse) {
            return `${baseURL}/${routes[page].prev}`;
        }
        return `${baseURL}/${routes[page].next}`;
    };

    useEffect(() => {
        if (jobID) {
            getJob(parseInt(jobID, 10)).then((jobResponse: SimpleJob) => {
                setJob(jobResponse);
            });
        }
    }, [jobID, setJob]);

    const setJobAndRedirect = (
        newJob: SimpleJob,
        page: keyof typeof routes
    ) => {
        setJob(newJob);
        setRedirect(getPageURL(page));
    };

    const [date, setDate] = useState<DateYMDString>(
        moment().format('YYYY-MM-DD') as DateYMDString
    );

    const submitPosting = (
        jobDetails: SimpleJob,
        interviewDetails: CreateInterviewDetails
    ) => {
        createJob({
            ...jobDetails,
            companyID: currentCompany.id,
            createdByID: user.id
        }).then((jobResponse: SimpleJob) => {
            setJob(jobResponse);
            const interviewRequest =
                convertCreateInterviewDetailsToCreateInterviewDetailsRequest(
                    interviewDetails,
                    jobResponse.id
                );
            createInterview(interviewRequest).then(() => {
                setRedirect(getPageURL('complete'));
            });
        });
    };

    useEffect(() => {
        getEligibleUserCount({}).then((count: number) => {
            setCandidateCount(count);
        });
    }, [setCandidateCount]);

    const onClose = async () => {
        setRedirect(`/secure/${user.userID}/${user.levylRole}/dashboard`);
    };

    const JobPostRoutes = () => (
        <Switch>
            <Route path={`${path}/intro`}>
                <IntroSlide nextURL={getPageURL('intro')} onClose={onClose} />
            </Route>
            <Route path={`${path}/title`}>
                <TitleSlide
                    job={job}
                    setJob={(newJob) => setJobAndRedirect(newJob, 'title')}
                    candidateCount={candidateCount}
                    setCandidateCount={setCandidateCount}
                    query={query}
                    setQuery={setQuery}
                    prevURL={getPageURL('title', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/education`}>
                <EducationSlide
                    job={job}
                    setJob={(newJob) => setJobAndRedirect(newJob, 'education')}
                    candidateCount={candidateCount}
                    setCandidateCount={setCandidateCount}
                    query={query}
                    setQuery={setQuery}
                    prevURL={getPageURL('education', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/environment`}>
                <EnvironmentSlide
                    job={job}
                    setJob={(newJob) =>
                        setJobAndRedirect(newJob, 'environment')
                    }
                    candidateCount={candidateCount}
                    setCandidateCount={setCandidateCount}
                    query={query}
                    setQuery={setQuery}
                    prevURL={getPageURL('environment', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/salary`}>
                <SalarySlide
                    job={job}
                    setJob={(newJob) => setJobAndRedirect(newJob, 'salary')}
                    candidateCount={candidateCount}
                    setCandidateCount={setCandidateCount}
                    query={query}
                    setQuery={setQuery}
                    prevURL={getPageURL('salary', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/description`}>
                <DescriptionSlide
                    job={job}
                    setJob={(newJob) =>
                        setJobAndRedirect(newJob, 'description')
                    }
                    candidateCount={candidateCount}
                    prevURL={getPageURL('description', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/interviewcalendar`}>
                <CalendarSlide
                    date={date}
                    setDate={setDate}
                    interview={interview}
                    setInterview={setInterview}
                    candidateCount={candidateCount}
                    nextURL={getPageURL('interviewcalendar')}
                    prevURL={getPageURL('interviewcalendar', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/interviewtime`}>
                <TimesSlide
                    currentDate={date}
                    job={job}
                    interview={interview}
                    setInterview={setInterview}
                    submitPosting={submitPosting}
                    candidateCount={candidateCount}
                    nextURL={getPageURL('interviewtime')}
                    prevURL={getPageURL('interviewtime', true)}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/complete`}>
                <CompleteSlide nextURL={getPageURL('complete')} />
            </Route>
        </Switch>
    );

    return (
        <Fragment>
            {location.pathname.includes('/edit/') ? (
                <MainContainer
                    sideBarButtons={defaultSideBarButtons}
                    firstName={user.name.firstName}
                    photoURL={user.name.imageURL}
                    signOut={() => {
                        auth?.signOut();
                    }}
                    scrollButtons={scrollButtons}
                >
                    <JobPostRoutes />
                </MainContainer>
            ) : (
                <MainContainer public fullWidth>
                    <JobPostRoutes />
                </MainContainer>
            )}
            {redirect && <Redirect to={redirect} />}
        </Fragment>
    );
}
