import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import RichText from '../../global/components/RichText';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        description: yup.string().required(),
        shortDescription: yup
            .string()
            .required()
            .max(
                300,
                'Share a shorter description of the job to attract candidates.'
            )
    })
    .required();

export default function DescriptionSlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            description: props.job.description,
            shortDescription: props.job.shortDescription
        }
    });

    const onSubmit = (data: {
        description: string;
        shortDescription: string;
    }) => {
        props.setJob({
            ...props.job,
            description: data.description,
            shortDescription: data.shortDescription
        });
    };

    return (
        <ColumnCard
            closeScreen
            step={2}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
            onClose={props.onClose}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                    <Typography color='primary'>
                        <label htmlFor='description'>
                            {content.description.label}
                        </label>
                    </Typography>
                    <Typography>
                        <p>{content.description.body}</p>
                    </Typography>
                    <RichText
                        id={'description'}
                        control={control as unknown as Control<FieldValues>}
                    />
                </div>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                    <Typography color='primary'>
                        <label htmlFor='shortDescription'>
                            {content.shortDescription.label}
                        </label>
                    </Typography>
                    <Typography>
                        <p>{content.shortDescription.body}</p>
                    </Typography>
                    <RichText
                        id={'shortDescription'}
                        control={control as unknown as Control<FieldValues>}
                        simple
                        error={errors.shortDescription?.message}
                    />
                </div>
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {content.common.back}
                    </TextButton>
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
