import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getEligibleUserCount } from '../../../services/user';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { WorkingPreference } from '../../../types/enum';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import RadioButton from '../../global/components/RadioButton';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        workEnvironment: yup.string().oneOf(['remote', 'hybrid', 'office'])
    })
    .required();

export default function EnvironmentSlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            workPreference: props.job.workPreference
        }
    });

    const onSubmit = (data: { workPreference: WorkingPreference }) => {
        props.setJob({
            ...props.job,
            workPreference: data.workPreference
        });
    };

    const { query, setQuery, setCandidateCount } = props;

    useEffect(() => {
        const subscription = watch((value) => {
            const { workPreference } = value;
            setQuery({
                ...query,
                work_environment: workPreference
            });
            if (workPreference) {
                getEligibleUserCount({
                    ...query,
                    work_environment: workPreference
                }).then((count: number) => {
                    setCandidateCount(count);
                });
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, query, setQuery, setCandidateCount]);

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
            onClose={props.onClose}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <RadioButton
                    id='workPreference'
                    title={content.environment.label}
                    color='primary'
                    error={!!errors.workPreference}
                    errorText={content.common.errors.required}
                    control={control as unknown as Control<FieldValues>}
                    labels={content.environment.options}
                />
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {content.common.back}
                    </TextButton>
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
