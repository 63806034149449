import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getEligibleUserCount } from '../../../services/user';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import TextInput from '../../global/components/TextField/TextField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        title: yup.string().required()
    })
    .required();

export default function TitleSlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            title: props.job.title
        }
    });

    const onSubmit = (data: { title: string }) => {
        props.setJob({
            ...props.job,
            title: data.title
        });
    };

    const { query, setQuery } = props;

    useEffect(() => {
        const subscription = watch((value) => {
            const { title } = value;
            setQuery({
                ...query,
                title
            });
        });
        return () => subscription.unsubscribe();
    }, [watch, query, setQuery]);

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
            onClose={props.onClose}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    id='title'
                    label={content.title.title.label}
                    color='primary'
                    error={!!errors.title}
                    errorText={content.common.errors.required}
                    placeholder={content.title.title.placeholder}
                    control={control as unknown as Control<FieldValues>}
                    onBlur={() => {
                        getEligibleUserCount(props.query).then(
                            (count: number) => {
                                props.setCandidateCount(count);
                            }
                        );
                    }}
                    type='string'
                />
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {content.common.back}
                    </TextButton>
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
