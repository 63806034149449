// TODO:
/* eslint max-lines: off */
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CreateInterviewDetails } from '../../../types/interview';
import { SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import Select from '../../global/components/SelectField';
import TextInput from '../../global/components/TextField/TextField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { DateYMDString } from '../BookInterview/types';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';
import { generateEndTime, generateOptions } from './CreateJob.utils';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    slots: yup.array().of(
        yup.object().shape({
            duration: yup.string().oneOf(['30', '45', '60']).required(),
            startTime: yup.string(),
            endTime: yup.string()
        })
    )
});

export default function TimesSlide(props: {
    currentDate: DateYMDString;
    job: SimpleJob;
    interview: CreateInterviewDetails;
    setInterview: (interview: CreateInterviewDetails) => void;
    submitPosting: (
        jobDetails: SimpleJob,
        interviewDetails: CreateInterviewDetails
    ) => void;
    candidateCount: number;
    nextURL: string;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const history = useHistory();
    const [timeSlots, setTimeSlots] = useState<Array<any>>([]);
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            slots: [
                {
                    date: props.currentDate,
                    duration: '30',
                    startTime: '',
                    endTime: ''
                }
            ]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'slots'
    });

    const onSubmit = () => {
        const { details } = props.interview;
        props.submitPosting(props.job, {
            ...props.interview,
            details: [...details, ...timeSlots]
        });
    };

    const addInterview = () => {
        const { details } = props.interview;
        props.setInterview({
            ...props.interview,
            details: [...details, ...timeSlots]
        });
        history.push(props.prevURL);
    };

    useEffect(() => {
        const subscription = watch((value) => {
            const { slots } = value;
            if (slots) {
                setTimeSlots([...slots]);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
            onClose={props.onClose}
        >
            <Typography variant='body2' fontWeight='400' sx={{ mb: 2 }}>
                {content.time.title}
            </Typography>
            <div
                style={{
                    padding: '10px 20px 10px 20px',
                    width: 'fit-content',
                    border: '1px solid #6C757D',
                    borderRadius: '2rem',
                    marginBottom: '1rem'
                }}
            >
                <Typography variant='body2' color='primary' fontWeight='400'>
                    {moment(props.currentDate).format('MMM D, YYYY')}
                </Typography>
            </div>
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => (
                    <div key={field.id}>
                        <TextInput
                            id='date'
                            color='primary'
                            label={''}
                            placeholder={''}
                            value={props.currentDate}
                            control={control as unknown as Control<FieldValues>}
                            type='hidden'
                        />
                        <Select
                            id={`slots.${index}.duration`}
                            label={content.time.duration.label}
                            color='primary'
                            error={!!errors.slots?.[index]?.duration}
                            errorText={content.common.errors.required}
                            control={control as unknown as Control<FieldValues>}
                            options={content.time.duration.options}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginRight: '6rem'
                            }}
                        >
                            <Select
                                id={`slots.${index}.startTime`}
                                label={content.time.startTime.label}
                                color='primary'
                                error={!!errors.slots?.[index]?.startTime}
                                errorText={content.common.errors.required}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                                options={generateOptions(
                                    timeSlots[index]?.duration || field.duration
                                )}
                            />
                            <TextInput
                                disabled={true}
                                id={`slots.${index}.endTime`}
                                color='primary'
                                label={content.time.endTime.label}
                                placeholder={''}
                                value={generateEndTime(
                                    timeSlots[index]?.startTime ||
                                        field.startTime,
                                    timeSlots[index]?.duration || field.duration
                                )}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                                type='string'
                            />
                            {fields.length > 1 && (
                                <IconButton
                                    color='primary'
                                    aria-label={content.time.remove.ariaLabel}
                                    component='label'
                                    sx={{ ml: 1 }}
                                    onClick={() => remove(index)}
                                >
                                    <HighlightOffIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                ))}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        size='large'
                        onClick={() => {
                            append({
                                date: props.currentDate,
                                duration: '15',
                                startTime: '',
                                endTime: ''
                            });
                        }}
                    >
                        <AddCircleOutlineIcon
                            color='primary'
                            fontSize='inherit'
                        />
                    </IconButton>
                    <Typography color='primary'>
                        {content.time.addSlot}
                    </Typography>
                </div>
                <NextSteps>
                    <TextButton onClick={addInterview}>
                        {content.time.addDate}
                    </TextButton>
                    <StyledButton variant='contained' sx={{ m: 2 }} submit>
                        {content.common.post}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
